// Colors

$black: #000000;
$white: #ffffff;
$main: #ef1d22;
$grey: #aeacac;
$dark: #313131;
// $example: #333333;

//fonts

$base-font: "Roboto";
$mont-font: "Mont";
$gilroy-font: "Gilroy";

:root {
	//colors
	--main: #{$main};
	--black: #{$black};
	--white: #{$white};
	--grey: #{$grey};
	--dark: #{$dark};
	// --example: #{$example};

	//fonts
	--base-font: #{$base-font};
	--mont-font: #{$mont-font};
	--gilroy-font: #{$gilroy-font};
}
