.comments {

	&__row {
		display: flex;
		align-items: center;

	}

	&__head {
		margin-bottom: 1.5rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 2.5rem;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
	}

	&__avatar {
		background-color: #AEACAC;
		flex: none;
		width: 3.4rem;
		height: 3.4rem;
		margin-right: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 2.2rem;
		font-weight: 500;
		color: #ffffff;

		@media screen and (min-width: 1024px) {
			width: 5rem;
			height: 5rem;
			margin-right: 1.5rem;
			font-size: 3.2rem;
		}
	}

	&__name {
		color: #313131;
		font-size: 1.4rem;
		line-height: 1;
		margin-bottom: 0.5rem;
		font-weight: 600;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-bottom: 1rem;
		}
	}

	&__status {
		color: #AEACAC;
		font-size: 1.3rem;
		line-height: 1;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
		}
	}

	&__text {
		font-size: 1.4rem;
		color: #313131;
		line-height: 1.25;
		margin-bottom: 1.5rem;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-bottom: 2.5rem;
		}
	}

	&__block {
		padding-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			padding-bottom: 6rem;
		}
	}

	&__like-wrap {
		display: flex;
		align-items: center;
		margin-right: 1rem;

		@media screen and (min-width: 1024px) {
			margin-right: 1.6rem;
		}
	}

	&__like-num {
		color: #313131;
		font-size: 1.3rem;
		margin-left: 0.5rem;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
		}
	}

	&__like {
		display: flex;
		width: 2rem;
		height: 2rem;

		@media screen and (min-width: 1024px) {
			width: 2.6rem;
			height: 2.6rem;
		}

		svg {
			transition: all 0.3s ease;
			stroke: #AEACAC;
			width: 100%;
			height: 100%;
		}

		&.active {
			svg {
				stroke: var(--main);
				fill: var(--main);
			}
		}

		&:hover {
			svg {
				stroke: var(--main);
			}
		}
	}

	&__reply {
		color: #AEACAC;
		font-size: 1.3rem;
		font-weight: 500;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
		}
	}

	&__date {
		margin-left: auto;
		font-size: 1.3rem;
		color: #AEACAC;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
		}
	}

	&__reply-form {
		margin-top: 2rem;
	}
}

.comment-form {
	margin-bottom: 3rem;
	display: flex;
	position: relative;

	@media screen and (min-width: 1024px) {
		margin-bottom: 5.4rem;
	}

	input {
		border-color: #AEACAC;
		color: #313131;

		@media screen and (max-width: 1023px) {
			padding: 1rem 6rem 1rem 1.5rem;
		}

		&::placeholder {
			color: #AEACAC;
		}
	}

	.popup-form__valid {
		width: 100%;
	}

	.btn {
		width: 25rem;
		margin-left: -1px;

		svg {
			display: none;
		}

		@media screen and (max-width: 1023px) {
			width: 4.5rem;
			height: 4.5rem;
			font-size: 0;
			background-color: transparent;
			padding: 0;
			position: absolute;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 2.1rem;
				height: 1.8rem;
				display: block;
			}
		}
	}

}

.comments-count {
	font-size: 2rem;
	margin-top: 4rem;
	margin-bottom: 3rem;
	color: #313131;
	font-weight: 600;

	@media screen and (min-width: 1024px) {
		font-weight: 700;
		margin-top: 6rem;
		margin-bottom: 4rem;
		font-size: 3.2rem;
	}
}

.comments-pagination {
	padding-top: 1.5rem;
	border-top: 1px solid #AEACAC;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (min-width: 1024px) {
		padding-top: 3rem;
	}

	&__load {
		font-size: 1.4rem;
		text-decoration: underline;
		color: #313131;
		font-weight: 500;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__pages {
		display: flex;
	}

	&__page {
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #AEACAC;
		margin-right: 0.7rem;
		font-size: 1.4rem;
		color: #AEACAC;
		font-weight: 700;
		transition: all 0.3s ease;

		@media screen and (min-width: 1024px) {
			width: 5rem;
			height: 5rem;
			margin-right: 3rem;
			font-size: 2.2rem;
			border-width: 2px;
		}

		&:last-child {
			margin-right: 0;
		}

		&.active {
			border-color: var(--main);
			background-color: var(--main);
			color: #ffffff;
		}
	}
}

.comments-reply {
	padding-left: 3.5rem;
	position: relative;
	border-left: 1px solid #AEACAC;

	&:after {
		position: absolute;
		width: 2.5rem;
		height: 1px;
		content: '';
		background-color: #AEACAC;
		left: 0;
		top: 1.7rem;

		@media screen and (min-width: 1024px) {
			top: 2.5rem;
		}
	}

	&:first-child {
		&:before {
			position: absolute;
			content: '';
			width: 1px;
			height: 5rem;
			top: -5rem;
			left: -1px;
			background-color: #AEACAC;
			z-index: 1;

			@media screen and (min-width: 1024px) {
				height: 6rem;
				top: -6rem;
			}
		}
	}


	&:last-child {
		border: none;

		&:before {
			position: absolute;
			content: '';
			width: 1px;
			height: 6rem;
			top: 1.7rem;
			transform: translateY(-100%);
			left: 0;
			background-color: #AEACAC;
			z-index: 1;

			@media screen and (min-width: 1024px) {
				top: 2.5rem;
			}
		}
	}


	@media screen and (min-width: 1024px) {
		padding-left: 4.5rem;
	}
}