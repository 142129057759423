.blog-section {
	padding: 3.5rem 0;
	@media screen and (min-width: 1024px) {
		padding: 0 0 17rem;
	}
}

.blog-row {
	display: flex;
	flex-wrap: wrap;
	position: relative;

	@media screen and (min-width: 768px) {
		&:after {
			position: absolute;
			content: "";
			background-color: var(--white);
			width: 2px;
			height: 100%;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
}