.cart-section {
	padding: 4rem 0 2rem;

	@media screen and (min-width: 1024px) {
		padding: 12.5rem 0;
	}

	.section-title__num {
		right: 10rem;

		@media screen and (min-width: 768px) {
			right: -10rem;
			top: 10%;
		}
	}

	.order {
		padding: 0 0 3rem;
	}

}

.cart-wrap {}

.cart-col {
	width: 100%;

	@media screen and (min-width: 1024px) {
		width: 50%;
	}

	&:first-child {
		border-right: 0;
		padding: 0;

		@media screen and (min-width: 1024px) {
			padding: 0 5rem 5rem 0;
		}
	}

	&:last-child {
		border-left: 0;
		padding: 4rem 0 0 0;

		@media screen and (min-width: 1024px) {
			padding: 0 0 5rem 10rem;
			border-left: 1px solid var(--main);
		}
	}
}

.cart {
	&__option-wrap {
		display: none;

		@media screen and (min-width: 1024px) {
			display: flex;
		}
	}

	&__empty {
		color: var(--dark);
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 142%;
		max-width: 26rem;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			max-width: 60rem;
			line-height: 200%;
		}
	}

	&__empty-btn {
		color: var(--main);
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 142%;

		&:hover {
			text-decoration: underline;
		}

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			line-height: 200%;
		}
	}



}

.order {

	&__head {
		margin-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 6rem;
		}
	}

	&__free-row {
		flex-direction: column;
		align-items: flex-start;

		@media screen and (min-width: 1024px) {
			align-items: center;
			flex-direction: row;
		}
	}

	&__free-wrap {
		margin-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 12.5rem;
		}
	}

	&__free-info {
		margin-right: 0;
		margin-bottom: 0.5rem;
		font-size: 1.4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 0 !important;
		}
	}

	&__free-info {
		margin-right: 0;
		margin-bottom: 0.5rem;
		font-size: 1.4rem;

		@media screen and (min-width: 1024px) {
			margin-right: 8rem;
			font-size: 2.2rem;
		}
	}

	&__line {
		align-items: flex-end;

		@media screen and (min-width: 1024px) {
			align-self: unset;
		}
	}

	&__line-wrap {
		border: 0;
		padding-bottom: 0;

		@media screen and (min-width: 1024px) {
			padding-bottom: 5rem;
			border-bottom: 1px solid var(--main);
		}
	}

	&__line-title {
		font-size: 1.4rem;
		width: 6.5rem;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			width: 13.5rem;
		}
	}

	&__dots {
		margin-bottom: 0;

		@media screen and (min-width: 1024px) {
			margin-bottom: 1rem;
		}
	}

	&__price {
		width: 7rem;
		font-size: 2rem;

		@media screen and (min-width: 1024px) {
			width: 13.5rem;
			font-size: 3.2rem;
		}
	}

	&__total {
		align-items: center;
		margin-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			align-items: unset;
			margin-bottom: 8rem;
		}
	}

	&__total-title {
		font-size: 2rem;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
		}
	}

	&__info {
		margin-top: 5rem;
		max-width: 70%;
		font-weight: 500;
		font-size: 1.4rem;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-top: 8rem;
			max-width: 50%;
			font-weight: 400;
		}
	}
}

.recommendation-wrap {
	.section-title {
		margin-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 10rem;
		}
	}

	.section-title__num {
		right: 0;

		@media screen and (min-width: 768px) {
			right: -5rem;
		}
	}


	.product-col {
		margin: 2rem 0;
		width: 100%;

		@media screen and (min-width: 768px) {
			width: 50%;
		}

		@media screen and (min-width: 1024px) {
			width: 25%;
		}

		&:nth-child(2n) {
			.product {
				border-left: 1px solid var(--main);

				@media screen and (min-width: 768px) {
					border-left: none;
				}
			}
		}

		&:last-child {
			margin: 2rem 0 !important;
		}
	}
}

.contact-us {
	color: var(--main);
	text-decoration: underline;
}