.filter {
	width: 100%;
	position: relative;
	background-color: #fff;
	border-radius: 2.2rem;

	&__head {
		align-items: center;
		justify-content: space-between;
		margin-bottom: 5rem;
		display: none;

		@media screen and (min-width: 1024px) {
			display: flex;
		}
	}

	&__wrap {
	}

	&__scroll-wrap {
	}

	&__title {
		font-weight: 700;
		font-size: 1.4rem;
		color: var(--dark);

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
		}
	}

	&__block {
		position: relative;
		transition: all 0.3s ease;
		border-bottom: 1px solid var(--grey);
	}

	&__block-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		padding: 1rem 0;
		min-height: 4rem;
		border-bottom: 1px solid transparent;

		@media screen and (min-width: 1024px) {
			padding: 1rem 0;
			min-height: 6rem;
		}

		&.active {
			border-color: var(--grey);
			.filter__plus {
				transform: rotate(45deg);
			}
		}
	}

	&__block-name {
		font-weight: 700;
		font-size: 1.4rem;
		color: var(--dark);
		margin-right: 1.7rem;
		white-space: nowrap;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}

	&__block-row {
		width: 100%;
		display: flex;
		align-items: center;
	}

	&__block-info {
		width: 3rem;
		height: 3rem;
		flex: none;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__block-row {
		width: 100%;
	}

	&__plus {
		flex: none;
		width: 2rem;
		height: 2rem;
		transition: all 0.3s ease;
		@media screen and (min-width: 1024px) {
			width: 2.8rem;
			height: 2.8rem;
		}
		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__block-body {
		max-height: 0;
		overflow: hidden;
		transition: all 0.3s;
	}

	&__block-descr {
		padding: 2.8rem 0;
		@media screen and (min-width: 1024px) {
			padding: 2rem 0;
		}
	}
}

.filter-buttons {
	margin-top: 2rem;

	@media screen and (min-width: 1024px) {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}
}

.filter-show {
	width: 100%;
	min-height: 4.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 1rem 2rem;
	color: var(--white);
	font-size: 1.4rem;
	line-height: 1;
	background-color: var(--grey);
	margin-bottom: 1.5rem;
	transition: all 0.3s ease;

	@media screen and (min-width: 1024px) {
		min-height: 6.4rem;
		font-size: 2.2rem;
	}

	&:hover {
		background-color: var(--dark);
	}
}

.filter-reset {
	width: 100%;
	color: var(--dark);
	font-size: 1.4rem;
	line-height: 1;
	font-weight: 700;
	text-decoration: underline;

	@media screen and (min-width: 1024px) {
		font-size: 2.2rem;
	}

	&:hover {
		text-decoration: none;
	}
}
