.empty-cart {
	padding-bottom: 4rem;
	margin-bottom: 4rem;
	border-bottom: 1px solid var(--main);

	@media screen and (min-width: 1024px) {
		padding-bottom: 10rem;
		margin-bottom: 10rem;
		border-width: 1px;
	}

	.section-title {
		max-width: 20rem;

		@media screen and (min-width: 1024px) {
			max-width: 45rem;
		}
	}

	.cart__head {
		margin-bottom: 3rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 10rem;
		}
	}
}