.partner-section {
	padding: 0 0 3rem;
	@media screen and (min-width: 1024px) {
		padding: 0 0 15rem;
	}
}

.partner-row {
	display: flex;
	flex-wrap: wrap;
	margin: -1rem;
}

.partner {
	width: 50%;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: 1024px) {
		width: calc(100% / 6);
	}

	img {
		max-width: 100%;
		width: auto;
		height: auto;
	}
}
