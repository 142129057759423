.news-block {
	padding: 0 0 4rem 0;

	@media screen and (min-width: 1024px) {
		padding: 0 0 10rem 0;
	}

	&--left {
		.news-block__row {
			display: flex;
			flex-direction: column-reverse;

			@media screen and (min-width: 1024px) {
				flex-direction: row;
			}
		}
	}

	&:last-child {
		padding-bottom: 0;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: -2rem 0;

		@media screen and (min-width: 1024px) {

			margin: 0 -2.4rem;
		}
	}

	&__col {
		width: 100%;
		padding: 2rem 0;

		@media screen and (min-width: 1024px) {
			padding: 0 2.4rem;
			width: 50%;
		}

		img {
			max-width: 100%;
			width: auto;
			height: auto;
			display: block;
		}

		li,
		p {
			font-size: 1.4rem;
			font-weight: 400;
			margin-bottom: 2rem;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
				margin-bottom: 4rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		li {
			margin-bottom: 0;
			list-style-type: "– ";
		}

		ul {
			margin-bottom: 2rem;

			@media screen and (min-width: 1024px) {
				margin-bottom: 4rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		h4 {
			font-size: 1.4rem;
			font-weight: 600;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}

			@media screen and (min-width: 1024px) {
				font-size: 3.2rem;
				line-height: 2;
				margin-bottom: 4rem;
			}
		}
	}
}

.news-block-del {
	margin-bottom: 4rem;
	width: 100%;
	height: 1px;
	background-color: var(--main);

	@media screen and (min-width: 1024px) {
		margin: 0 0 10rem 0;
	}
}


.news-block-section {
	.section-title {
		margin-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			max-width: 75%;
			margin-bottom: 7rem;
		}
	}

}

.news-block-num {
	display: block;
	text-align: right;
	color: var(--main);
	font-weight: 500;
	font-size: 1.4rem;

	@media screen and (min-width: 1024px) {
		font-size: 3.2rem;
		margin-right: calc(50% + -6rem);
		margin-bottom: -4rem;
	}
}

.news-block-main {
	.news-block {
		&:first-child {
			.news-block__col {
				&:last-child {

					img {
						margin-top: 4rem;
					}
				}
			}
		}
	}
}