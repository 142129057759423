.loader-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--white);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}
