.accept {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 1.5rem;

	@media screen and (min-width: 1024px) {
		margin-bottom: 1rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__input {
		width: 0;
		height: 0;
		visibility: hidden;

		&:checked {
			& + .accept__label {
				.accept__check {
					border-color: var(--main);
					background-color: var(--main);
				}

				.accept__name {
					color: var(--main);
				}
			}
		}
	}

	&__label {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	&__check {
		width: 2.5rem;
		height: 2.5rem;
		flex: none;
		border: 1px solid var(--grey);
		transition: all 0.3s ease;
		background-color: var(--white);

		@media screen and (min-width: 1024px) {
			width: 2.2rem;
			height: 2.2rem;
		}
	}

	&__name {
		color: var(--dark);
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 1;
		margin-left: 2rem;
		text-align: left;
		transition: all 0.3s ease;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			font-weight: 700;
		}
	}
}
