.cart-wrap {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--main);
	margin-bottom: 4rem;

	@media screen and (min-width: 1024px) {
		border-width: 1px;
		margin-bottom: 10rem;
	}
}

.cart-col {
	position: relative;
	width: 50%;

	&:first-child {
		padding: 0 5rem 0 0;
		border-right: 1px solid var(--main);
	}

	&:last-child {
		padding: 0 0 0 10rem;
		border-left: 1px solid var(--main);
	}
}

.cart {
	&__head {
		margin-bottom: 2rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 5rem;
		}
	}

	&__option-wrap {
		display: flex;
		border-bottom: 1px solid var(--main);
	}

	&__option {
		display: flex;
		align-items: center;
		min-height: 8rem;

		&:first-child {
			width: 40%;
			border-right: 1px solid var(--main);
			padding: 1rem 2rem 1rem 0;
		}

		&:last-child {
			width: 60%;
			justify-content: center;
			border-left: 1px solid var(--main);
			padding: 1rem 2rem;
		}
	}

	&__option-btn {
		color: var(--dark);
		font-size: 3.2rem;
		font-weight: 600;
		line-height: 100%;
		text-decoration: underline;
	}

	&__account {
		margin-top: 3rem;

		@media screen and (min-width: 1024px) {
			margin-top: 8rem;
		}
	}

	&__account-info {
		max-width: 85%;
		color: var(--dark);
		font-size: 2.2rem;
		font-weight: 700;
		line-height: 145%;
		margin-bottom: 11rem;
	}

	&__account-btns {
		display: flex;
		justify-content: space-between;
		margin-bottom: 7.5rem;
	}

	&__account-btn {
		&:first-child {
			width: calc(60% - 2.5rem);
		}

		&:last-child {
			width: calc(40% - 2.5rem);
		}
	}

	&__rec {
		@media screen and (min-width: 1024px) {
			.product-row {
				margin: 0 -3rem;
			}

			.product-col {
				width: 50%;
			}

			.product {
				padding: 0 3rem;
			}
		}
	}

	.popup-form {
		&__valid {
			margin-bottom: 3rem;

			@media screen and (min-width: 1024px) {
				margin-bottom: 5rem;
			}
		}

		&__label {
			font-size: 1.4rem;
			font-weight: 600;
			margin-bottom: 1.4rem;
			color: var(--dark);

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
				font-weight: 500;
				margin-bottom: 2rem;
			}
		}

		&__input {
			&.invalid {
				border-width: 3px;
			}
		}
	}
}

.checkbox {
	position: relative;
	display: flex;
	align-items: center;

	&__input {
		&:checked {
			&+.checkbox__label {
				.checkbox__label-squer {
					border-color: var(--main);
					background-color: var(--main);
				}
			}
		}
	}

	&__label {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__label-text {
		color: var(--dark);
		font-size: 3.2rem;
		font-weight: 600;
		line-height: 100%;
	}

	&__label-squer {
		width: 3.3rem;
		height: 3.3rem;
		border: 2px solid var(--grey);
		margin-right: 2rem;
	}
}

.hidden {
	opacity: 0;
	z-index: -1;
	width: 0;
	height: 0;
	font-size: 0;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

.sub-button {
	color: var(--dark);
	font-size: 2.2rem;
	font-weight: 700;
	text-decoration: underline;
}