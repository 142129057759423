.header {
	padding: 1.9rem 0 0;

	@media screen and (min-width: 1024px) {
		padding: 3.2rem 0 0;
	}

	&__wrap {
		display: flex;
		align-items: flex-start;
		border-bottom: 1px solid var(--main);

		@media screen and (min-width: 1024px) {
			border-bottom: none;
		}

		&.white {
			border-color: var(--white);

			.mobile-nav__item {
				border-color: var(--white);
			}

			.burger {
				span {
					background-color: var(--white);
				}

				&.active {
					span {
						background-color: var(--main);
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) {}
}

.logo {
	display: flex;
	width: 8.7rem;
	height: auto;

	@media screen and (min-width: 1024px) {
		width: 28.7rem;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}


.header-shadow {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 81rem;
	background: linear-gradient(180deg, rgba(104, 106, 108, 1) 0%, rgba(255, 255, 255, 1) 100%);
}