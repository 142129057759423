.cart-product {
	display: flex;
	align-items: flex-start;
	padding: 4rem 0;
	border-bottom: 1px solid var(--main);

	@media screen and (min-width: 1024px) {
		padding: 6rem 0;
		border-width: 1px;

		&:last-child {
			border-bottom: none;
		}
	}

	&.selected {
		background-color: #f7efef;
	}

	&__img {
		flex: none;
		width: 16rem;
		height: 16rem;
		margin-right: auto;

		@media screen and (min-width: 1024px) {
			width: 22rem;
			height: 29rem;
			margin-right: 3rem;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__info {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__line {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 1rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

	}

	&__first-select {
		width: 100%;
		margin-top: 3rem;

		@media screen and (min-width: 1024px) {
			width: 30rem;
			margin-right: 10rem;
			margin-top: 0;
			margin-bottom: 5rem;
			order: 1;
		}

		.product-select {
			margin-bottom: 3rem;

			@media screen and (min-width: 1024px) {
				margin-bottom: 0;
			}
		}
	}

	&__second-select {
		@media screen and (min-width: 1024px) {
			order: 2;
			margin-bottom: 5rem;
		}

		.product-select {
			margin-bottom: 0;

			.product-selecast__control {
				border: none;
			}

			.product-selecast__single-value {
				color: var(--grey);
				border-bottom: 2px dotted var(--grey);
			}
		}
	}

	&__name {
		color: var(--main);
		font-size: 2rem;
		font-weight: 600;
		line-height: 100%;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			margin-right: 5.5rem;
		}
	}

	&__type {
		color: var(--dark);
		font-size: 1.4rem;
		font-weight: 400;
		margin-left: auto;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-left: 0;
			font-weight: 500;
		}
	}

	&__count-wrap {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 0;
		}
	}

	&__count-btn {
		width: 4.6rem;
		height: 4.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--grey);
		transition: all 0.3s ease;

		&:hover {
			border-color: var(--main);
		}

		@media screen and (min-width: 1024px) {
			width: 6.4rem;
			height: 6.4rem;
			border-width: 1px;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;

			@media screen and (min-width: 1024px) {
				width: 3.2rem;
				height: 3.2rem;
			}
		}
	}

	&__count-value {
		color: var(--dark);
		font-size: 2rem;
		font-weight: 700;
		min-width: 2rem;
		text-align: center;
		margin: 0 auto;

		@media screen and (min-width: 1024px) {
			font-size: 4rem;
			min-width: 5rem;
			margin: 0 1rem;
		}
	}

	&__option-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (min-width: 1024px) {
			margin-left: auto;
		}
	}

	&__option {
		width: 4.6rem;
		height: 4.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--main);
		margin-right: 2rem;

		@media screen and (min-width: 1024px) {
			width: 6.4rem;
			height: 6.4rem;
			border-width: 1px;
		}

		svg {
			width: 2.8rem;
			height: 2.8rem;

			@media screen and (min-width: 1024px) {
				width: 4rem;
				height: 4rem;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__price {
		color: var(--dark);
		font-size: 2rem;
		font-weight: 600;
		margin-left: auto;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			margin-left: auto;
			font-weight: 700;
			order: 4;
			width: 11rem;
			text-align: right;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		max-width: 13.5rem;
		width: 100%;
		margin-top: auto;

		@media screen and (min-width: 1024px) {
			flex-direction: row;
			order: 3;
			margin-top: 0;
			max-width: 45rem;
		}
	}
}