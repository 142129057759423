.faq {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #fff;
	border-radius: 2.2rem;

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4.5rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 2.7rem;
		}
	}

	&__scroll-wrap {
		height: calc(100vh - 24.2rem);
		overflow-y: auto;
		border-top: 1px solid var(--main);
		margin: 0 0 3.5rem;

		@media screen and (min-width: 1024px) {
			height: auto;
			padding: 0;
			margin: 0 0 4.2rem;
			overflow-y: visible;
		}
	}

	&__title {
	}

	&__block {
		position: relative;
		transition: all 0.3s ease;

		// &.active {
		// 	.faq__block-body {
		// 		animation: accord 0.3s forwards;
		// 	}
		// }

		&.active {
			.faq__block-body {
				border-bottom: 1px solid var(--main);
			}
		}
	}

	&__block-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		padding: 0.9rem 0;
		min-height: 6rem;
		border-bottom: 1px solid var(--main);

		@media screen and (min-width: 1024px) {
			padding: 3rem 0;
		}

		&.active {
			border-bottom: 1px solid var(--main);

			.faq__plus {
				transform: rotate(45deg);
			}
		}
	}

	&__block-name {
		font-weight: 700;
		font-size: 2.2rem;
		color: var(--dark);
		margin-right: 1.7rem;
		line-height: 200%;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			line-height: 175%;
		}
	}

	&__block-info {
		width: 3rem;
		height: 3rem;
		flex: none;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__plus {
		flex: none;
		width: 3.2rem;
		height: 3.2rem;
		transition: all 0.3s ease;

		@media screen and (min-width: 1024px) {
			width: 5.9rem;
			height: 5.9rem;
		}

		svg {
			width: 100%;
			height: 100%;
		}

		line {
			stroke-width: 1px;
		}
	}

	&__block-body {
		max-height: 0;
		overflow: hidden;
		transition: all 0.3s;
	}

	&__block-descr {
		padding: 4.9rem 0 3.2rem;
	}

	&__block-text {
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 142%;
		color: var(--dark);

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			line-height: 145%;
		}

		& + & {
			margin-top: 2rem;
		}
	}
}
