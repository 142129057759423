.error-section {
	padding: 10rem 0;

	@media screen and (min-width: 1024px) {
		padding: 25rem 0;
	}
}

.error-wrap {
	&__row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: row;
		margin: -1rem;

		@media screen and (min-width: 1024px) {
			align-items: flex-end;
			margin: 0 -7rem;
			flex-direction: row;
		}
	}

	&__col {
		width: 50%;
		padding: 1rem;

		@media screen and (min-width: 1024px) {
			padding: 0 7rem;

			&:nth-child(1) {
				width: 55%;
			}

			&:nth-child(2) {
				width: 45%;
			}
		}
	}
}

.error {
	display: flex;
	flex-direction: column;

	.sub-link {
		margin-left: 0;
		margin-top: 0;

		svg {
			flex: none;
			width: 1.5rem !important;
			height: 1.5rem !important;

			@media screen and (min-width: 1024px) {
				width: 3.4rem !important;
				height: 3.2rem !important;
			}
		}
	}

	&__title {
		font-size: 8rem;
		font-weight: 950;
		font-family: var(--mont-font);
		color: var(--main);
		line-height: 63%;

		@media screen and (min-width: 768px) {
			font-size: 14rem;
		}

		@media screen and (min-width: 1024px) {
			font-size: 44.4rem;
		}
	}

	&__text {
		display: block;
		font-size: 1.4rem;
		font-weight: 500;
		color: var(--dark);
		line-height: 150%;
		margin-bottom: 2rem;
		

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			margin-left: 5rem;
			line-height: 196%;
			margin-bottom: 10rem;
			max-width: 60rem;
		}
	}
}