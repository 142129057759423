.popup {
	display: none;
	position: absolute;
	top: 6rem;
	left: 50%;
	transform: translateX(-50%);
	height: calc(100vh - 6rem);
	z-index: 18;
	width: 100%;
	background-color: var(--white);

	@media screen and (min-width: 1024px) {
		border: 1px solid var(--main);
		border-top: none;
		height: 100vh;
		top: 0;
	}

	&.active {
		display: block;
		animation: fadeIn 0.5s forwards;
	}

	&--products {
		max-width: 115rem;

		.popup__wrap {
			padding: 3.2rem 2.4rem;

			@media screen and (min-width: 1024px) {
				padding: 15rem 15rem;
			}
		}

		.order-product {
			padding-bottom: 4rem;
			margin-bottom: 2rem;
			border-bottom: 1px solid var(--main);

			@media screen and (min-width: 1024px) {
				border-width: 1px;
				margin: 0;
				padding: 6rem 0;
			}
		}

		.order-product__row {
			@media screen and (min-width: 1024px) {
				display: flex;
				align-items: center;
			}
		}

		.order-info__dots {
			@media screen and (min-width: 1024px) {
				width: 13rem;
				margin: auto 0 0.5rem 0;
			}
		}

		.order-info__price-line {
			@media screen and (min-width: 1024px) {
				margin-right: 6.4rem;
			}
		}

		.order-product__img {
			@media screen and (min-width: 1024px) {
				height: 28rem;
				margin-right: 5rem;
				margin-left: -6rem;
			}
		}

		.order-product__title {
			@media screen and (min-width: 1024px) {
				margin: 0 5.5rem 0 0;
			}
		}

		.order-product__info {
			@media screen and (min-width: 1024px) {
				margin-right: 0;
			}
		}
	}

	&--log-reg {
		max-width: 145rem;

		.popup__wrap {
			padding: 3.2rem 2.4rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 20rem;
			}
		}

		.section-title__num {
			@media screen and (max-width: 1023px) {
				display: none;
			}
		}

		.btn {
			@media screen and (min-width: 1024px) {
				width: 40rem;
			}
		}
	}

	&--login {
		max-width: 95rem;

		.popup__wrap {
			padding: 3.2rem 2.4rem;

			@media screen and (min-width: 1024px) {
				padding: 8rem 10rem;
			}
		}

		.popup-form {
			padding-top: 5rem;

			@media screen and (min-width: 1024px) {
				padding-top: 8rem;
			}
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 6rem;
			}
		}
	}

	&--registration {
		max-width: 90rem;

		.popup__wrap {
			padding: 3.2rem 2.4rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.5rem;
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup-form__btns {
			margin-top: 2.8rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}
	}

	&--forgot-pass {
		max-width: 95rem;

		.popup__wrap {
			padding: 3.2rem 2.5rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.3rem;

			@media screen and (min-width: 1024px) {
				padding-top: 4.4rem;
			}
		}

		.popup-form__btns {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup__block {
			margin-top: 3rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}

		.popup__block-text {
			font-size: 1.4rem;
			line-height: 142%;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}
	}

	&--new-pass {
		max-width: 95rem;

		.popup__wrap {
			padding: 3.2rem 2.5rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.3rem;

			@media screen and (min-width: 1024px) {
				padding-top: 7rem;
			}
		}

		.popup-form__btns {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup__block {
			margin-top: 3rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}

		.popup__block-text {
			font-size: 1.4rem;
			line-height: 142%;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}
	}

	&--additional-email {
		max-width: 95rem;

		.popup__wrap {
			padding: 3.2rem 2.5rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.3rem;

			@media screen and (min-width: 1024px) {
				padding-top: 4.4rem;
			}
		}

		.popup-form__btns {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup__block {
			margin-top: 3rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}

		.popup__block-text {
			font-size: 1.4rem;
			line-height: 142%;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}
	}

	&--additional-tel {
		max-width: 95rem;

		.popup__head {
			@media screen and (min-width: 1024px) {
				margin-right: -5rem;
			}
		}

		.popup__wrap {
			padding: 3.2rem 2.5rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.3rem;

			@media screen and (min-width: 1024px) {
				padding-top: 4.4rem;
			}
		}

		.popup-form__btns {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup__block {
			margin-top: 3rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}

		.popup__block-text {
			font-size: 1.4rem;
			line-height: 142%;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}
	}

	&--recovery-code {
		max-width: 95rem;

		.popup__wrap {
			padding: 3.2rem 2.5rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.3rem;

			@media screen and (min-width: 1024px) {
				padding-top: 4.4rem;
			}
		}

		.popup-form__btns {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup__block {
			margin-top: 3rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}

		.popup__block-text {
			font-size: 1.4rem;
			line-height: 142%;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}
	}

	&--recovery-tel {
		max-width: 95rem;

		.popup__wrap {
			padding: 3.2rem 2.5rem;

			@media screen and (min-width: 1024px) {
				padding: 6rem 15rem;
			}
		}

		.popup-form {
			padding-top: 3.3rem;

			@media screen and (min-width: 1024px) {
				padding-top: 4.4rem;
			}
		}

		.popup-form__btns {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}

		.popup__block {
			margin-top: 3rem;

			@media screen and (min-width: 1024px) {
				margin-top: 4.4rem;
			}
		}

		.popup__block-text {
			font-size: 1.4rem;
			line-height: 142%;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}

		.popup-form__valid + .popup-form__valid {
			margin-top: 1.5rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3rem;
			}
		}
	}

	&__wrap {
		margin: 0 auto;
		height: 100%;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 1rem;
			background-color: var(--grey);

			@media screen and (min-width: 1024px) {
				width: 2rem;
			}
		}

		&::-webkit-scrollbar-thumb {
			width: 1rem;
			background: var(--main);

			@media screen and (min-width: 1024px) {
				width: 2rem;
			}
		}
	}

	&__step {
		& + & {
			margin-top: 10rem;

			@media screen and (min-width: 1024px) {
				margin-top: 8rem;
			}
		}
	}

	&__block {
		display: flex;
		flex-direction: column;
		margin-top: 2rem;

		@media screen and (min-width: 1024px) {
			margin-top: 5rem;
		}

		&--row {
			flex-direction: column;

			@media screen and (min-width: 1024px) {
				flex-direction: row;
				align-items: flex-end;
				justify-content: space-between;
			}

			.popup__block-text {
				margin-bottom: 3rem;

				@media screen and (min-width: 1024px) {
					width: 56%;
					margin-right: 3.5rem;
					margin-bottom: 0;
				}
			}
		}
	}

	&__body {
	}

	&__block-text {
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 142%;
		color: var(--dark);

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			line-height: 196%;
		}
	}

	&__footer-text {
		font-size: 1.4rem;
		font-weight: 600;
		color: var(--dark);
		border-bottom: 1px solid var(--dark);

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			font-weight: 700;
		}
	}

	&__footer {
		text-align: center;
		margin-top: 3rem;

		@media screen and (min-width: 1024px) {
			margin-top: 10rem;
		}
	}
}

.popup-form {
	display: flex;
	flex-direction: column;

	.product-selecast__single-value,
	.product-selecast__option {
		text-align: left !important;
	}

	.product-selecast__value-container {
		padding: 0.2rem 1.7rem !important;

		@media screen and (min-width: 1024px) {
			padding: 0.2rem 2rem !important;
		}
	}

	&__valid {
		display: flex;
		flex-direction: column;

		&.invalid {
			.popup-form__invalid {
				display: block;
			}
		}
	}

	&__input-wrap {
		margin-bottom: 2.4rem;
	}

	&__input {
		padding: 1rem 3rem;
		border: 1px solid var(--main);
		color: var(--main);
		font-size: 1.4rem;
		font-weight: 500;
		height: 4.5rem;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
			height: 6.6rem;
			padding: 1.5rem 5rem;
		}

		&::placeholder {
			color: var(--main);
		}
	}

	&__invalid {
		display: none;
		font-weight: 500;
		font-size: 1.4rem;
		color: var(--main);
		margin-top: 1.5rem;
		margin-left: 2.5rem;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
			margin-left: 5rem;
		}
	}

	&__password-recover {
		display: flex;
		justify-content: flex-end;
	}

	&__text {
		font-size: 1.4rem;
		font-weight: 400;
		margin-left: auto;
		line-height: 160%;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
			font-weight: 500;
		}

		&--underline {
			border-bottom: 1px solid var(--dark);
		}
	}

	&__policy {
		border-bottom: 1px solid var(--dark);
		margin-left: 0.5rem;
		color: var(--black);
	}

	&__descr {
		margin-top: 2.7rem;

		@media screen and (min-width: 1024px) {
			margin-top: 4rem;
		}
	}

	&__btns {
		display: flex;
		flex-direction: column;
		margin-top: 10rem;

		@media screen and (min-width: 1024px) {
			margin-top: 8rem;
		}

		.btn + .btn {
			margin-top: 2.5rem;
		}
	}
}

.close-cross {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--grey);
	background-color: var(--white);
	position: absolute;
	top: 1rem;
	right: 2rem;

	@media screen and (min-width: 1024px) {
		width: 6rem;
		height: 6rem;
		top: 2rem;
		right: 4rem;
		border-width: 4px;
	}

	svg {
		width: 2rem;
		height: 2rem;
		transform: rotate(45deg);

		@media screen and (min-width: 1024px) {
			width: 4rem;
			height: 4rem;
		}
	}
}

@keyframes fadeIn {
	0% {
		transform: translate(-50%, -100%);
	}

	100% {
		transform: translate(-50%, 0%);
	}
}

@keyframes fadeOut {
	0% {
		transform: translate(-50%, 0%);
	}

	100% {
		transform: translate(-50%, -100%);
	}
}
