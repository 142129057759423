.wrapper {
	width: 100%;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.main {
	min-height: 50vh;
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

section {
	width: 100%;
}