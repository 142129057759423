.comments-auth {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 2rem;
	background-color: #EEEEEE;
	margin-bottom: 3rem;

	@media screen and (min-width: 1024px) {
		padding: 2rem 3rem;
		margin-bottom: 5.4rem;
	}

	svg {
		flex: none;
		width: 1.7rem;
		height: 2.2rem;
		margin-right: 1.7rem;

		@media screen and (min-width: 1024px) {
			width: 2.3rem;
			height: 3rem;
			margin-right: 2rem;
		}
	}

	&__text {
		font-size: 1.4rem;
		color: #AEACAC;
		line-height: 1.4;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}

	&__btn {
		text-decoration: underline;
		margin: 0 5px;

		&:hover {
			text-decoration: none;
		}
	}
}