@font-face {
	font-family: "Roboto";
	src:
		url("../assets/fonts/Roboto-Thin.woff2") format("woff2"),
		url("../assets/fonts/Roboto-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		url("../assets/fonts/Roboto-Light.woff2") format("woff2"),
		url("../assets/fonts/Roboto-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		url("../assets/fonts/Roboto-Regular.woff2") format("woff2"),
		url("../assets/fonts/Roboto-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		url("../assets/fonts/Roboto-Medium.woff2") format("woff2"),
		url("../assets/fonts/Roboto-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		url("../assets/fonts/Roboto-Bold.woff2") format("woff2"),
		url("../assets/fonts/Roboto-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Roboto";
	src:
		url("../assets/fonts/Roboto-Black.woff2") format("woff2"),
		url("../assets/fonts/Roboto-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mont";
	src:
		url("../assets/fonts/Mont-Black.woff2") format("woff2"),
		url("../assets/fonts/Mont-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gilroy";
	src:
		url("../assets/fonts/Gilroy-Bold.woff") format("woff2"),
		url("../assets/fonts//Gilroy-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../assets/fonts/Gilroy-Heavy.woff2') format('woff2'),
        url('../assets/fonts/Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


