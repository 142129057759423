.blog {
	width: 100%;
	height: auto;
	margin-bottom: 4rem;

	&:last-child {
		margin-bottom: 0;
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 0;
		width: 50%;
		border-right: 1px solid var(--main);
		border-bottom: 1px solid var(--main);

		&:nth-child(even) {
			padding-left: 2.8rem;
		}

		&:nth-child(odd) {
			padding-right: 2.8rem;
		}
	}

	&__head {
		display: flex;
		align-items: flex-start;
		margin-bottom: 4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 0;
		}
	}

	&__name {
		max-width: 65rem;
		width: 100%;
		color: var(--main);
		font-family: var(--mont-font);
		font-size: 2rem;
		line-height: 200%;
		font-weight: 900;
		text-decoration: underline;
		word-break: break-word;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			flex: none;
			line-height: 160%;
			// overflow: hidden;
			// text-overflow: ellipsis;
			// display: -webkit-box;
			// -webkit-line-clamp: 4;
			// line-clamp: 4;
			// -webkit-box-orient: vertical;
			margin-top: 2rem;
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__teg-wrap {
		display: flex;
		border-right: none;
		border-bottom: 1px solid var(--main);
		width: 100%;

		@media screen and (min-width: 768px) {
			border-bottom: none;
			margin-top: auto;
		}

		@media screen and (min-width: 1024px) {
			width: calc(100% - 40rem);
			margin: auto 0 0 auto;
		}
	}

	&__teg {
		width: 50%;
		padding: 1rem 2rem 1rem 0;
		display: flex;
		align-items: center;
		border-right: 1px solid var(--main);

		@media screen and (min-width: 1024px) {
			padding: 2rem 2rem 2rem 0;
			min-height: 9.1rem;
		}

		&:last-child {
			padding: 1rem 2rem;
			border-bottom: none;
			border-right: none;
			display: flex;
			justify-content: center;
			@media screen and (min-width: 1024px) {
				padding: 2rem;
			}
		}
	}

	&__teg-text {
		color: var(--main);
		font-size: 1.4rem;
		font-weight: 700;
		line-height: 143%;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
			line-height: 160%;

			-webkit-line-clamp: 2;
			line-clamp: 2;
		}
	}

	&__img {
		width: 100%;
		height: 18rem;
		margin-bottom: 3rem;

		@media screen and (min-width: 1024px) {
			width: 30rem;
			height: 15.7rem;
			margin: 2.5rem 10rem 0 0;
			flex: none;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__desc {
		width: 100%;
		color: var(--dark);
		font-size: 1.4rem;
		line-height: 143%;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
			font-weight: 500;
			margin-top: 2rem;
			line-height: 160%;
		}
	}

	&__body {
		margin-bottom: 2rem;
		@media screen and (min-width: 1024px) {
			display: flex;
			margin-bottom: 4rem;
		}
	}
}
