.mobile-nav {
	display: flex;
	margin-left: auto;

	@media screen and (min-width: 1024px) {
		display: none;
	}

	&__item {
		width: 4.5rem;
		height: 4rem;
		border-left: 1px solid var(--main);
		display: flex;
		align-items: center;
		justify-content: center;

		&:last-child {
			width: 3.8rem;
			justify-content: flex-end;
		}

		svg {
			width: 3rem;
			height: 3rem;
		}
	}

	&.white {
		.mobile-nav__count {
			color: var(--white);
		}

		.mobile-nav__btn {
			&.filled {
				.prof-svg {
					fill: var(--white);
				}
			}
		}
	}

	&__btn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		&.filled {
			.prof-svg {
				fill: var(--main);
			}
		}
	}

	&__count {
		font-size: 1.4rem;
		font-weight: 500;
		font-family: "Roboto";
		color: var(--main);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -25%);
	}
}

.burger {
	width: 2.7rem;
	height: 2.7rem;
	position: relative;
	z-index: 19;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&.active {
		span {
			&:nth-child(1) {
				top: 9px;
				transform: rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				bottom: 9px;
				transform: rotate(-45deg);
			}
		}
	}

	span {
		position: relative;
		width: 100%;
		height: 1px;
		background-color: var(--main);
		transition: all 0.3s ease;

		&:nth-child(1) {
			margin-bottom: 0.8rem;
		}

		&:nth-child(3) {
			margin-top: 0.8rem;
		}
	}
}