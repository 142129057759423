.delivery-flex {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1rem;
	@media screen and (min-width: 1024px) {
		justify-content: flex-start;
		margin-bottom: 3rem;
	}
}

.delivery-btn {
	font-size: 1.4rem;
	font-weight: 500;

	&:hover {
		text-decoration: underline;
	}

	@media screen and (min-width: 1024px) {
		font-size: 2.2rem;
	}

	&.delivery-btn-last {
		margin: 0 0 0 2rem;
		position: relative;

		@media screen and (min-width: 1024px) {
			margin: 0 0 0 3rem;
		}

		&::after {
			position: absolute;
			content: "";
			left: -1rem;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			height: 1.5rem;
			background-color: var(--main);

			@media screen and (min-width: 1024px) {
				width: 1px;
				height: 2.5rem;
				left: -1.5rem;
			}
		}
	}
}
