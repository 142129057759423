.order {
	padding: 4rem 0;

	@media screen and (min-width: 1024px) {
		padding-bottom: 3rem;
	}

	&__head {
		display: flex;
		align-items: flex-start;
		margin-bottom: 6.5rem;
	}

	.section-title {
		margin-right: 4rem;
	}

	&__free-wrap {
		margin-bottom: 12.5rem;
	}

	&__free-value {
		display: block;
		color: var(--dark);
		font-size: 2rem;
		font-weight: 400;
		margin-bottom: 1.4rem;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			font-weight: 500;
			margin-bottom: 3.2rem;
		}

		span {
			color: var(--main);
			font-weight: 700;

			@media screen and (min-width: 1024px) {
				font-weight: 600;
			}
		}
	}

	&__free-row {
		display: flex;
		align-items: center;
	}

	&__free-info {
		color: var(--dark);
		font-size: 2.2rem;
		font-weight: 500;
		margin-right: 8rem;
	}

	&__free-status {
		display: flex;
		align-items: center;
	}

	&__free-rb {
		width: 0.9rem;
		height: 0.9rem;
		border: 1px solid var(--main);
		border-radius: 1px;
		transform: rotate(45deg);
		margin-right: 0.6rem;

		@media screen and (min-width: 1024px) {
			width: 1.5rem;
			height: 1.5rem;
			border-width: 1px;
			margin-right: 1rem;
		}

		&.active {
			background-color: var(--main);
		}
	}

	&__count {
		color: var(--main);
		line-height: 286%;
		font-size: 1.4rem;
		font-weight: 500;
		margin-right: 1.4rem;
		white-space: nowrap;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-right: 4rem;
		}
	}

	&__weight {
		color: var(--main);
		line-height: 286%;
		font-size: 1.4rem;
		font-weight: 500;
		margin-left: auto;
		white-space: nowrap;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}

	&__line-wrap {
		padding-bottom: 5rem;
		margin-bottom: 5rem;
		border-bottom: 1px solid var(--main);
	}

	&__line {
		width: 100%;
		display: flex;
		margin-bottom: 3.6rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__line-title {
		color: var(--dark);
		font-size: 2.2rem;
		font-weight: 500;
		width: 13.5rem;
		margin-right: 2rem;
		white-space: nowrap;
		flex: none;
	}

	&__dots {
		width: 100%;
		border-bottom: 1px dotted var(--grey);
		margin-bottom: 1rem;
	}

	&__total-title,
	&__price {
		color: var(--dark);
		font-size: 3.2rem;
		font-weight: 700;
		white-space: nowrap;
		flex: none;
	}

	&__price {
		width: 13.5rem;
		margin-left: 2rem;
		text-align: right;

		@media screen and (min-width: 1024px) {}
	}

	&__total-title {
		margin-right: 2rem;
	}

	&__total {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8rem;
	}

	&__info {
		max-width: 50%;
		color: var(--black);
		font-size: 2rem;
		line-height: 160%;
		display: block;
		margin-top: 8rem;
	}

	&__tab-wrap {
		display: flex;
		border-bottom: 1px solid var(--main);
		margin-bottom: 3rem;
	}

	&__tab {
		color: var(--dark);
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 1;
		text-align: center;
		height: 4.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid var(--main);
		padding: 1.5rem 2.5rem;
		width: 100%;
		white-space: nowrap;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			font-weight: 700;
			padding: 1.5rem 4rem;
			height: 6.6rem;
		}

		&:last-child {
			border-right: none;
		}

		&.active {
			background-color: var(--main);
			color: var(--white);
		}
	}
}

.order-product__btns {
	margin: 4rem 0 2rem;
}

.btn {
	width: 100%;
	height: 4.5rem;
	padding: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: var(--white);
	font-size: 1.4rem;
	font-weight: 700;
	transition: all 0.3s ease;

	@media screen and (min-width: 1024px) {
		height: 6.6rem;
		font-size: 2.2rem;
	}

	&.primary {
		background: var(--main);

		&:hover {}
	}

	&.secondary {
		background: var(--dark);

		&:hover {}
	}

	&:hover {
		opacity: 0.85;
	}
}

.disabled {
	cursor: not-allowed;
	filter: grayscale(100%);
}

.desktop {
	@media screen and (max-width: 1023px) {
		display: none !important;
	}
}

.mobile {
	@media screen and (min-width: 1024px) {
		display: none !important;
	}
}

.promo-row {
	@media screen and (min-width: 1024px) {
		display: flex;
	}

	&__input {
		margin-bottom: 1.5rem;

		@media screen and (min-width: 1024px) {
			margin: 0 2rem 0 0;
		}
	}
}

.order {
	.faq__block-name {
		font-size: 2rem;
		font-weight: 400;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			font-weight: 500;
		}
	}

	.faq__block-head {
		transition: all 0.3s ease;

		@media screen and (min-width: 1024px) {
			border-bottom: none;
			border-top: 1px solid var(--main);
			margin: 0 0 -5rem 0;
		}

		&.active {
			border-bottom: none;
			margin-bottom: 0;
		}
	}

	.faq__block-body {
		@media screen and (min-width: 1024px) {
			border-bottom: none !important;
		}
	}

	.faq__block-descr {
		padding: 1rem 0 2.6rem;

		@media screen and (min-width: 1024px) {
			padding: 0 0 1rem 0;
		}
	}

	.faq__block-head {}
}

.promo-error {
	display: block;
	margin-top: 1rem;
	font-size: 2rem;
	color: var(--main);
}

.position-relative {
	position: relative;
}

.tg-row {
	margin-bottom: 1rem;

	@media screen and (min-width: 1024px) {
		display: flex;
		margin-top: 3rem;
		.btn {
			width: 20rem;
		}

		.tg-row__wrap {
			width: 100%;
		}
	}

	.promo-row__input {
		width: 100%;
		border-color: var(--grey);

		&::placeholder {
			color: #AEACAC;
		}
	}

	@media screen and (min-width: 1024px) {
		margin-bottom: 0;
	}
}