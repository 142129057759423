.product-select {
	height: 4.5rem;
	margin-bottom: 1.4rem;

	@media screen and (min-width: 1024px) {
		height: 6.6rem;
		margin-bottom: 3rem;
	}

	.product-selecast__control {
		border-color: var(--grey);
		border-radius: 0;
		height: 100%;
		border-width: 1px;
	}

	.product-selecast__single-value {
		font-size: 1.4rem;
		font-style: normal;
		font-weight: 500;
		color: var(--dark);
		text-align: center;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}

	.product-selecast__indicator {
		margin-right: 2rem;
		padding: 0;
		width: 1.2rem;
		height: 1.2rem;
		border-bottom: 1px solid var(--grey);
		border-right: 1px solid var(--grey);
		transform: rotate(45deg);
		margin-bottom: 1rem;

		@media screen and (min-width: 1024px) {
			border-width: 1px;
			width: 1.4rem;
			height: 1.4rem;
		}

		svg {
			display: none;
		}
	}

	.product-selecast__menu {
		margin: 0;
		border-radius: 0;
		box-shadow:
			0 0 1px 0px hsla(0, 0%, 0%, 0.1),
			0 4px 11px hsla(0, 0%, 0%, 0.1);
	}

	.product-selecast__menu-list {
		max-height: 100%;
		padding: 0;
	}

	.product-selecast__option {
		padding: 1.7rem;
		font-size: 1.4rem;
		font-weight: 500;
		text-align: center;
		border-right: 1px solid var(--grey);
		border-left: 1px solid var(--grey);
		border-bottom: 1px solid var(--grey);
		color: var(--dark);

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			padding: 2rem;
		}

		&--is-selected {
			background-color: var(--white);
		}

		&--is-focused {
			background-color: var(--white);
		}

		&--is-disabled {
			pointer-events: none;
			color: var(--grey);
		}

		&:hover {
			color: var(--main);
			cursor: pointer;
		}
	}
}

.product-selecast__control--is-focused,
.product-selecast__control--menu-is-open {
	border-width: 1px;
}

.css-1n6sfyn-MenuList {
	padding: 0 !important;
}

.css-t3ipsp-control {
	box-shadow: none !important;
	// border: 2px solid var(--main) !important;
}

.cart__account {
	.product-selecast__placeholder {
		font-size: 1.4rem;
		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}
	.product-selecast__input-container {
		font-size: 1.4rem;
		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}
	.product-select {
		margin-bottom: 0;
	}
	.product-selecast__menu-list {
		max-height: 33.3rem;
	}

	.product-selecast__control {
		border-color: var(--main);

		&:hover {
			border-color: var(--main);
		}
	}

	.product-select {
		&.invalid {
			.product-selecast__control {
				border-width: 3px;
			}
		}
	}

	.product-selecast__input-container {
		margin: 0!important;
	}
}
