.nav {
	position: fixed;
	right: -100%;
	top: 0;
	width: 28rem;
	padding: 2rem 2.4rem;
	background-color: var(--white);
	height: 100vh;
	transition: all 0.3s ease;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	overflow-y: auto;

	&.white {
		.nav__del {
			background-color: var(--white);
		}

		.mobile-nav__count {
			color: var(--white);
		}

		.loader-overlay {
			background-color: transparent;
		}

		.loader {
			span {
				border-color: var(--white) !important;
				background-color: var(--white);

				&:first-child {
					animation: fade1-white 0.6s infinite;
				}

				&:nth-child(2) {
					animation: fade2-white 0.6s infinite;
				}

				&:nth-child(3) {
					animation: fade3-white 0.6s infinite;
				}

				&:nth-child(4) {
					animation: fade4-white 0.6s infinite;
				}
			}
		}

		@media screen and (min-width: 1024px) {

			.nav__item,
			.nav__list {
				border-color: var(--white);
			}

			.nav__link {
				color: var(--white);

				&.active {
					background-color: var(--white);
					color: #686a6c;
				}
			}
		}
	}

	&.active {
		right: 0;
		z-index: 18;
	}

	@media screen and (min-width: 1024px) {
		position: static;
		box-shadow: none;
		width: 120rem;
		height: auto;
		flex: none;
		margin-left: auto;
		overflow: hidden;
		padding: 0;
		background-color: transparent;
		display: flex;
		flex-direction: column-reverse;
	}

	&__list {
		display: flex;
		flex-direction: column;
		min-height: 6rem;

		@media screen and (min-width: 1024px) {
			margin-top: -1px;
			margin-right: -1px;
			flex-wrap: wrap;
			flex-direction: row;
			border-bottom: 1px solid var(--main);
		}

		@media screen and (min-width: 1024px) {
			&:first-child {
				.nav__item {
					&:nth-child(1) {
						width: 15rem;
					}

					&:nth-child(2) {
						width: 20rem;
					}

					&:nth-child(3) {
						width: 15rem;
					}

					&:nth-child(4) {
						width: 10rem;
					}

					&:nth-child(5) {
						width: 30rem;
					}

					&:nth-child(6) {
						width: 20rem;
					}

					&:nth-child(7) {
						width: 10rem;
					}
				}
			}

			&:last-child {
				.nav__item {
					
					&:nth-child(1) {
						width: 20rem;
					}

					&:nth-child(2) {
						width: 15rem;
					}

					&:nth-child(3) {
						width: 15rem;
					}

					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						width: 15rem;
					}

					&:nth-child(7) {
						width: 25rem;
					}
				}
			}
		}
	}

	&__item {
		width: 100%;
		height: 4rem;
		display: flex;
		align-items: center;
		position: relative;
		border-bottom: 1px solid var(--main);

		@media screen and (min-width: 1024px) {
			height: 6rem;
			border-left: 1px solid var(--main);
			border-bottom: none;
			justify-content: center;
		}

		&--vk {
			@media screen and (max-width: 1023px) {
				order: 6;
			}
		}

		&.desktop {
			display: none;

			@media screen and (min-width: 1024px) {
				display: flex;
			}
		}
	}

	&__link {
		width: 100%;
		height: 100%;
		color: var(--main);
		font-family: "Gilroy";
		font-size: 1.4rem;
		font-weight: 700;
		z-index: 2;
		cursor: pointer;
		display: flex;
		align-items: center;

		@media screen and (min-width: 1024px) {
			font-size: 2rem;
			justify-content: center;
		}

		&--user {
			font-weight: 900;
		}

		&:hover {
			text-decoration: underline;
		}

		&.active {
			text-decoration: underline;
		}

		@media screen and (min-width: 1024px) {
			&.active {
				background-color: var(--main);
				color: var(--white);
				text-decoration: none;
			}
		}
	}

	&__del {
		width: 2px;
		height: 2.5rem;
		margin: 0 2rem;
		background-color: var(--main);
	}

	&__option {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		position: relative;

		@media screen and (min-width: 1024px) {
			justify-content: center;
		}

		svg {
			width: 3rem;
			height: 3rem;
		}

		.head-cart-svg {
			fill: var(--main);
		}

		.head-like-svg {
			stroke: var(--main);
		}

		&.active {
			background-color: var(--main);

			.head-cart-svg {
				fill: var(--white);
			}

			.head-like-svg {
				stroke: var(--white);
			}

			.mobile-nav__count {
				color: var(--white);
			}
		}
	}

	&__count {
		font-size: 1.4rem;
		font-weight: 500;
		font-family: "Roboto";
		color: var(--main);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -25%);
	}
}

@keyframes fade1-white {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: var(--white);
	}

	40% {
		background-color: transparent;
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes fade2-white {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: var(--white);
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes fade3-white {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: transparent;
	}

	60% {
		background-color: var(--white);
	}

	80% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes fade4-white {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: transparent;
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: var(--white);
	}

	100% {
		background-color: transparent;
	}
}