.footer {
	background-color: #313131;

	&__wrap {
		padding: 3rem 0;

		@media screen and (min-width: 1024px) {
			padding: 6.3rem 0 6rem;
		}
	}
}

.footer-wrap {
	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: -1.5rem;

		@media screen and (min-width: 1024px) {
			margin: 0;
		}

		&:after {
			content: "";
			display: block;
			width: calc(100% - 3rem);
			height: 2px;
			background-color: var(--white);
			margin: 1rem auto 3rem;

			@media screen and (min-width: 1024px) {
				width: 100%;
				margin-top: 6rem;
				margin-bottom: 4rem;
			}
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		&--second {
			align-items: center;

			.footer-wrap__col {
				@media screen and (min-width: 1024px) {
					width: calc(100% / 3);
				}
			}
		}
	}

	&__col {
		width: 100%;
		padding: 1.5rem;

		@media screen and (min-width: 1024px) {
			width: calc(100% / 3);
			padding: 0;
		}

		&--fulldesktop {
			width: 100%;

			@media screen and (min-width: 1024px) {
				width: calc(100% / 3);
			}
		}

		&--empty {
			display: none;

			@media screen and (min-width: 1024px) {
				display: none;
			}
		}
	}
}

.footer-info {
	height: 100%;

	&__list {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 2rem;
		color: var(--white);

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			margin-bottom: 3.5rem;
		}

		&--m0 {
			margin: 0;
		}
	}

	&__item {
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1;
		color: var(--white);

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}

		& + & {
			margin-top: 2rem;

			@media screen and (min-width: 1024px) {
				margin-top: 3.7rem;
			}
		}
	}

	&__link {
		color: inherit;
		transition: all 0.3s ease;

		&:hover {
			opacity: 0.6;
		}
	}

	&__socs {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
	}

	&__soc {
		width: 3rem;
		height: 3rem;

		& + & {
			margin-left: 4rem;
		}

		svg {
			width: 100%;
			height: 100%;
			display: block;
			transition: all 0.3s ease;
		}

		&:hover {
			svg {
				opacity: 0.6;
			}
		}
	}

	&__subscribe {
		font-size: 2rem;
		color: var(--white);
		font-weight: 700;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
		}
	}
	&__created {
		margin-top: auto;

		svg {
			width: 5.7rem;
			height: 1.5rem;
			margin-left: 0.7rem;
		}
	}
}

.footer-form {
	&__wrap {
		display: flex;
		align-items: center;
		flex-direction: column;

		@media screen and (min-width: 1024px) {
			flex-direction: row;
		}
	}

	&__input-message {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		transform: translateY(120%);
		font-size: 1.4rem;
		color: var(--white);
	}

	&__block {
		width: 100%;
		position: relative;

		@media screen and (min-width: 1024px) {
			width: auto;
			margin-right: 5rem;
		}

		&:last-child {
			margin-right: 0;
		}

		& + & {
			margin-top: 2rem;

			@media screen and (min-width: 1024px) {
				margin-left: auto;
				margin-top: 0;
			}
		}
	}

	&__input {
		height: 5rem;
		font-size: 2.2rem;
		background-color: transparent;
		color: var(--white);
		padding: 1.4rem 1rem !important;
		border-top: 2px solid var(--white) !important;
		border-bottom: 2px solid var(--white) !important;
		border-left: none !important;
		border-right: none !important;
		text-align: center;
		transition: all 0.3s ease;
		width: 100%;

		@media screen and (min-width: 1024px) {
			width: auto;
			height: 6.6rem;
			padding: 1.8rem 1rem !important;
		}

		&::placeholder {
			color: var(--white) !important;
		}

		&:focus {
			&::placeholder {
				opacity: 0;
			}
		}
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 2.2rem;
		font-weight: 700;
		line-height: 104%;
		background-color: var(--white);
		color: var(--black);
		padding: 1.4rem 4rem;
		width: 100%;

		@media screen and (min-width: 1024px) {
			width: auto;
			padding: 2.2rem 7rem;
		}

		&:hover {
			opacity: 0.6;
		}
	}
}
