.loader {
	width: 100%;
	display: flex;
	justify-content: center;

	span {
		height: 1rem;
		width: 1rem;
		border: 1px solid var(--main);
		background-color: transparent;
		margin: 0 0.25rem;

		@media screen and (min-width: 1024px) {
			height: 2rem;
			width: 2rem;
			margin: 0 0.5rem;
		}

		&:first-child {
			animation: fade1 0.6s infinite;
		}

		&:nth-child(2) {
			animation: fade2 0.6s infinite;
		}

		&:nth-child(3) {
			animation: fade3 0.6s infinite;
		}

		&:nth-child(4) {
			animation: fade4 0.6s infinite;
		}
	}
}

.infinite-scroll {
	margin-top: 4rem;
}

@keyframes fade1 {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: var(--main);
	}

	40% {
		background-color: transparent;
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes fade2 {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: var(--main);
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes fade3 {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: transparent;
	}

	60% {
		background-color: var(--main);
	}

	80% {
		background-color: transparent;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes fade4 {
	0% {
		background-color: transparent;
	}

	20% {
		background-color: transparent;
	}

	40% {
		background-color: transparent;
	}

	60% {
		background-color: transparent;
	}

	80% {
		background-color: var(--main);
	}

	100% {
		background-color: transparent;
	}
}
