.sub-section {
	&.section-first {
		@media screen and (min-width: 1024px) {
			.section-title__num {
				right: 9rem;
			}
		}
	}

	&.section-second {
		.section-desc {
			max-width: 70%;

			@media screen and (min-width: 1024px) {
				max-width: 100%;
			}
		}

		@media screen and (min-width: 1024px) {
			.section-block {
				&:first-child {
					width: 61%;
				}

				&:last-child {
					width: 39%;
				}
			}
			.section-title__num {
				right: 19rem;
			}
		}
	}

	&.section-second,
	&.section-third {
		.section-wrap {
			border-bottom: none;
		}
	}

	&.section-third {
		@media screen and (min-width: 1024px) {
			.section-block {
				&:first-child {
					width: 72%;
				}

				&:last-child {
					width: 28%;
				}
			}
		}
	}

	&.section-fourth {
		.section-wrap {
			border-top: 1px solid var(--main);
			padding: 6rem 0 4rem;
			@media screen and (min-width: 1024px) {
				border-width: 1px;
			}
		}

		@media screen and (min-width: 1024px) {
			.section-block {
				&:first-child {
					width: 60%;
				}

				&:last-child {
					width: 40%;
				}
			}
			.section-title__num {
				right: 16rem;
			}
		}
	}

	&.section-fifth {
		.section-wrap {
			border-bottom: none;
		}

		.sub-link {
			margin-top: 0;
		}

		@media screen and (min-width: 1024px) {
			.section-block {
				&:first-child {
					width: 60%;
				}

				&:last-child {
					width: 40%;
				}
			}
			.section-title__num {
				right: 16rem;
			}
		}
	}

	&.section-six {
		.section-wrap {
			border-bottom: none;
		}

		@media screen and (min-width: 1024px) {
			.section-block {
				&:first-child {
					width: 65%;
				}

				&:last-child {
					width: 35%;
				}
			}
		}
	}

	&.section-rec {
		.section-wrap {
			padding: 0;
			border-bottom: none;

			@media screen and (min-width: 1024px) {
				padding: 0 0 6rem 0;
			}
		}
		.section-block {
			&:last-child {
				display: none;
			}
		}
	}

	&.section-more {
		.section-title {
			max-width: 33rem;
			@media screen and (min-width: 1024px) {
				max-width: 100%;
			}
		}

		.section-desc {
			@media screen and (max-width: 1023px) {
				font-size: 2rem;
				font-weight: 700;
			}
		}
		.section-wrap {
			border: none;
			border-top: 1px solid var(--main);
			padding: 4rem 0 0 0;

			@media screen and (min-width: 1024px) {
				border: none;
				padding: 0 0 6rem 0;
			}
		}
	}
}

.section-wrap {
	border-bottom: 1px solid var(--main);
	padding: 3rem 0;

	@media screen and (min-width: 1024px) {
		padding: 6rem 0;
		border-width: 1px;
	}
}

.section-row {
	display: flex;
	flex-wrap: wrap;
	margin: -1.5rem;

	@media screen and (min-width: 1024px) {
		margin: -8rem;
	}
}

.section-block {
	padding: 1.5rem;
	width: 100%;

	@media screen and (min-width: 1024px) {
		padding: 8rem;
		width: 50%;
	}
}

.section-title {
	max-width: 30.5rem;
	width: 100%;
	position: relative;

	@media screen and (min-width: 768px) {
		max-width: fit-content;
	}

	&__text {
		color: var(--grey);
		font-family: var(--mont-font);
		font-size: 3.2rem;
		font-weight: 900;
		line-height: 124%;
		text-transform: uppercase;

		@media screen and (min-width: 1024px) {
			font-size: 8rem;
		}
	}

	&__num {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(130%, 30%);
		color: var(--main);
		font-size: 1.4rem;
		font-weight: 500;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			top: 1.5rem;
			right: -4rem;
			transform: translate(100%, 0%);
		}
	}
}

.section-desc {
	color: var(--dark);
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 142%;

	@media screen and (min-width: 1024px) {
		font-size: 3.2rem;
		font-weight: 700;
		line-height: 200%;
	}
}

.sub-link {
	display: flex;
	align-items: center;
	margin-top: 3rem;

	@media screen and (min-width: 1024px) {
		margin-top: 8rem;
		margin-left: -4.2rem;
	}

	span {
		color: var(--main);
		font-size: 1.4rem;
		font-weight: 700;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	svg {
		margin-right: 0.7rem;
		width: 10px;
		height: 10px;

		@media screen and (min-width: 1024px) {
			width: 2.7rem;
			height: 2.7rem;
			margin-right: 1.5rem;
		}
	}
}
