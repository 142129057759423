html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
img,
strong,
sub,
sup,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: baseline;
	background: transparent;
}

* {
	text-decoration: none;
	list-style: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

//rem
html {
	font-size: 10px;

	@media screen and (min-width: 1024px) {
		font-size: 5px;
	}
	@media screen and (min-width: 1280px) {
		font-size: 6px;
	}
	@media screen and (min-width: 1360px) {
		font-size: 7px;
	}
	@media screen and (min-width: 1440px) {
		font-size: 8px;
	}
	@media screen and (min-width: 1680px) {
		font-size: 9px;
	}
	@media screen and (min-width: 1920px) {
		font-size: 10px;
	}
}

body {
	background-color: var(--white);
	font-family: $base-font;
	font-weight: 400;
	font-size: 1.4rem;
	//   background-color: $body-bg;
	//   color: $font-color;
	//   &.overflow {
	//     overflow: hidden;
	//     touch-action: none;
	//     -ms-touch-action: none;
	//   }
}

a {
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	//   transition: $base-transition;
}

svg {
	//   transition: $base-transition;
}

a:hover,
a:active,
a:focus {
	text-decoration: none;
}

input {
	border: none;
	background-color: transparent;
	outline: none;
	-webkit-appearance: none;
}

textarea {
	resize: none;
	border: none;
	background-color: transparent;
	outline: none;
	-webkit-appearance: none;
}

button {
	padding: 0;
	cursor: pointer;
	border: none;
	background-color: transparent;
	//   transition: $base-transition;
	outline: none;
	-webkit-appearance: none;
}

select {
	cursor: pointer;
	border: none;
	background-color: transparent;
	//   transition: $base-transition;
	outline: none;
	-webkit-appearance: none;
}

.container {
	max-width: 179rem;
	width: 100%;
	margin: 0 auto;
	padding: 0 2.4rem;
}

//scroll
.scroll-wrap::-webkit-scrollbar {
	width: 4px;
	background-color: transparent;
}

.scroll-wrap::-webkit-scrollbar-thumb {
	width: 4px;
	background-color: #edeef8;
	border-radius: 10px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
