.catalog-section {
	padding: 4rem 0;

	@media screen and (min-width: 1024px) {
		padding: 0 0 9rem;
	}

	.product-row {
		@media screen and (min-width: 1024px) {
			margin: 0 -2.2rem;
		}
	}

	.product-col {
		width: 100%;

		@media screen and (min-width: 768px) {
			width: 50%;
		}

		@media screen and (min-width: 1024px) {
			width: 25%;
		}

		&:nth-child(2n) {

			.product {
				border-left: 1px solid var(--main);

				@media screen and (min-width: 768px) {
					border-left: none;
				}
			}
		}

	}
}

.product-row {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-right: -2px;

	@media screen and (min-width: 1024px) {
		&::after {
			content: "";
			width: 4px;
			height: calc(100% + 2px);
			background-color: var(--white);
			position: absolute;
			top: -2px;
		}

		&::after {
			right: -1px;
		}
	}
}