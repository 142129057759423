.range-inputs {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.range-input {
	width: calc(50% - 1rem);
	height: 4.5rem;
	border: 1px solid var(--grey);
	padding: 1rem;
	display: flex;
	align-items: center;

	@media screen and (min-width: 1024px) {
		height: 6.6rem;
	}

	&__text {
		font-size: 1.4rem;
		color: var(--grey);
		font-weight: 700;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}

	&__input {
		font-size: 1.4rem;
		color: var(--dark);
		line-height: 1;
		margin-left: 1rem;
		width: 100%;
		font-weight: 700;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}
}

.range-slider {
	margin-bottom: 1rem!important;
	padding: 0 1.6rem!important;

	@media screen and (min-width: 1024px) {
		padding: 0 1.5rem!important;
		margin-bottom: 2rem!important;
	}

	.MuiSlider-thumb {
		width: 2rem!important;
		height: 2rem!important;
		background-color: var(--main)!important;
		box-shadow: none!important;
		margin-left: -1.7rem!important;
		margin-top: -0.3rem!important;
	}

	.MuiSlider-rail,
	.MuiSlider-track {
		height: 0.4rem!important;
		background-color: var(--main)!important;
		color: var(--main)!important;
	}

	.MuiSlider-thumb {
		box-shadow: none !important;

		&::after,
		&::before {
			display: none!important;
		}
	}

	.MuiSlider-thumb {
		border-radius: 2px!important;
		transform: rotate(45deg) translateY(-50%)!important;
	}
}