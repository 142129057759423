.product-col {
	width: 100%;
	margin: 2rem 0;

	&:last-child {
		margin-bottom: 0;
	}

	@media screen and (min-width: 1024px) {
		width: 25%;
		margin: 0;
	}
}

.index-catalog {
	.product__price-wrap {
		min-height: 10.359rem;
	}
}

.product {
	border-right: 1px solid var(--main);
	border-left: 1px solid var(--main);
	padding: 0 3.2rem;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__head {
		margin-top: auto;
	}

	@media screen and (min-width: 1024px) {
		padding: 0 2.2rem;
		border-right: 1px solid var(--main);
		border-left: none;
	}

	&__placeholder {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		svg {
			width: 6rem;
			height: 6rem;
			margin-bottom: 2rem;
		}
	}

	&__placeholder-text {
		color: var(--main);
		font-size: 2rem;
		font-weight: 500;
	}

	&__info-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__new-wrap {
		width: 3.6rem;
		height: auto;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 2rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 3.5rem;
		}
	}

	&__name {
		color: var(--main);
		font-size: 2rem;
		font-weight: 600;
		font-weight: 160%;
		margin-right: 1rem;

		@media screen and (min-width: 1024px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
			font-size: 3.2rem;
		}
	}

	&__type {
		color: var(--dark);
		font-size: 1.4rem;
		font-weight: 500;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
		}
	}

	&__line {
		min-height: 1.6rem;
		display: flex;
		align-items: center;
		margin-bottom: 0.4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 0.8rem;
		}
	}

	&__line-text {
		color: var(--dark);
		font-size: 1.4rem;
		font-weight: 700;
		margin-right: 0.5rem;
		line-height: 1;
		width: 12rem;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			width: 20rem;
			margin-right: 1rem;
		}
	}

	&__line-degree {
		display: flex;
		align-items: center;
	}

	&__degree {
		width: 0.9rem;
		height: 0.9rem;
		border: 1px solid var(--main);
		border-radius: 1px;
		transform: rotate(45deg);
		margin-right: 0.9rem;

		@media screen and (min-width: 1024px) {
			width: 1.5rem;
			height: 1.5rem;
			border-width: 1px;
			margin-right: 1rem;
		}

		&.active {
			background-color: var(--main);
		}
	}

	&__img {
		width: 20rem;
		height: 20rem;
		margin: 0 auto 2rem;

		@media screen and (min-width: 1024px) {
			width: 27rem;
			height: 27rem;
			margin: 2.5rem auto 5rem;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__desc {
		display: block;
		color: var(--dark);
		font-size: 1.4rem;
		line-height: 145%;
		margin-bottom: 2rem;

		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		line-clamp: 4;
		-webkit-box-orient: vertical;


		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-bottom: 2.6rem;

			min-height: 12.556rem;
		}
	}

	&__price-wrap {
		margin-bottom: 2.4rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 4.6rem;
		}
	}

	&__price-line {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;

		@media screen and (min-width: 1024px) {
			margin-bottom: 3rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__price-opt {
		margin-left: 5rem;
	}

	&__price-block {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__price-checkbox {
		position: relative;
	}

	&__check-input {
		position: absolute;
		width: 0;
		height: 0;
		font-size: 0;
		opacity: 0;
		top: 0;
		left: 0;
		visibility: hidden;
		z-index: -1;

		&:checked {
			&+.product__check-label {
				background-color: var(--grey);
			}
		}
	}

	&__check-label {
		display: block;
		width: 2.5rem;
		height: 2.5rem;
		border: 1px solid var(--grey);
		margin-right: 2rem;
		cursor: pointer;
		transition: all 0.3s ease;

		@media screen and (min-width: 1024px) {
			width: 3.2rem;
			height: 3.2rem;
			margin-right: 2.3rem;
		}
	}

	&__weight {
		min-width: 5rem;
		color: var(--grey);
		font-size: 1.4rem;
		font-weight: 500;
		flex: none;
		margin-right: 0.5rem;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			min-width: 7.5rem;
		}
	}

	&__dots {
		width: 100%;
		height: 100%;
		border-bottom: 2px dotted var(--grey);
		margin: auto 0 0.8rem 0;

		@media screen and (min-width: 1024px) {
			margin: auto 0 1.2rem 0;
			border-bottom: 2px dotted var(--grey);
		}
	}

	&__price {
		min-width: 8rem;
		color: var(--dark);
		font-size: 2.3rem;
		font-weight: 500;
		flex: none;
		margin-left: 0.5rem;
		text-align: right;

		@media screen and (min-width: 1024px) {
			font-size: 3.2rem;
			min-width: 11rem;
		}
	}

	&__footer {
		// margin-top: auto;
	}

	&__select {
		color: var(--dark);
		font-size: 1.4rem;
		font-weight: 500;
		width: 100%;
		height: 4.5rem;
		border: 2px solid var(--grey);
		text-align: center;
		margin-bottom: 1.4rem;

		@media screen and (min-width: 1024px) {
			font-size: 2.2rem;
			margin-bottom: 3rem;
			height: 6.6rem;
		}
	}

	&__btn-wrap {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 1.5rem;

		@media screen and (min-width: 450px) {
			flex-wrap: nowrap;
		}

		button {
			border-color: var(--main);
		}

		.cart-product__count-value {
			margin: 0 1rem;

			@media screen and (min-width: 768px) {
				margin: 0;
			}
		}

		.cart-product__count-wrap {
			margin-bottom: 0;
		}

		.filter-show {
			margin-bottom: 0;
			font-size: 1.4rem;
			font-weight: 700;
			width: 100%;

			@media screen and (min-width: 1024px) {
				font-size: 1.7rem;
			}
		}
	}

	&__btn {
		width: 100%;
		height: 4.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;
		background-color: var(--main);
		margin-right: 1rem;
		transition: all 0.3s ease;

		@media screen and (min-width: 1024px) {
			margin-right: 2rem;
			height: 6.6rem;
		}

		&:hover {
			opacity: 0.85;
		}

		span {
			color: var(--white);
			font-size: 1.4rem;
			font-weight: 700;

			@media screen and (min-width: 1024px) {
				font-size: 2.2rem;
			}
		}

		svg {
			width: 2rem;
			height: 2rem;
			margin-right: 1.4rem;

			@media screen and (min-width: 1024px) {
				width: 3rem;
				height: 3rem;
				margin-right: 2rem;
			}
		}
	}

	&__fav {
		flex: none;
		width: 4.5rem;
		height: 4.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;
		border: 1px solid var(--main);

		@media screen and (min-width: 1024px) {
			width: 6.6rem;
			height: 6.6rem;
			border: 1px solid var(--main);
		}

		svg {
			width: 2.6rem;
			height: 2.5rem;

			@media screen and (min-width: 1024px) {
				width: 3.8rem;
				height: 3.5rem;
			}
		}

		&.active {
			svg {
				fill: var(--main);
				animation: fade-scale 0.5s forwards;
			}
		}
	}
}

@keyframes fade-scale {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1);
	}
}